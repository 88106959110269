<template>
  <div class="decision-wrapper">
    <div class="mb-32 component__header">
      <h1>निर्णयहरु</h1>
    </div>
    <div class="container decision__content">
      <div v-if="years.length">
        <div class="flex flex-decision-pdf mb-24">
          <div class="decision__filter multiselect__full-width">
            <Multiselect
              v-model="selected_year"
              :options="years"
              :placeholder="default_year"
              :canClear="false"
            />
          </div>
          <button
            @click="$store.dispatch('windowPrint')"
            class="btn download-pdf"
          >
            PDF डाउनलोड गर्नुहोस्
          </button>
        </div>
        <div
          v-for="(ele, index) in decisions"
          :key="index"
          class="decision__card mb-40"
        >
          <h3 class="decision__title mb-16">{{ ele.decision }}</h3>
          <ul class="decision__card--inner" v-if="ele.decision_listings">
            <li
              v-for="(decision_list, index) in ele.decision_listings"
              :key="index"
              class="decision__description mb-16"
            >
              {{ englishToNepaliNumber(index + 1) }}. {{ decision_list }}
            </li>
          </ul>
        </div>
      </div>
      <NoDataFound v-else />
    </div>
  </div>
  <PdfDownload ref="pdf">
    <template v-slot:content>
      <div class="flex flex-title">
        <h1 class="mb-16">निर्णयहरु</h1>
        <span class="selected-filter">{{ selected_year }}</span>
      </div>
      <div
        v-for="(ele, index) in decisions"
        :key="index"
        class="decision__card mb-40"
      >
        <h2 class="decision__title mb-16">{{ ele.decision }}</h2>
        <ul class="decision__card--inner">
          <li
            v-for="(decision_list, index) in ele.decision_listings"
            :key="index"
            class="decision__description mb-16"
          >
            {{ englishToNepaliNumber(index + 1) }}. {{ decision_list }}
          </li>
        </ul>
      </div>
    </template>
  </PdfDownload>
</template>
<script>
import { defineAsyncComponent } from "vue";
import LocalGovernmentRepo from "../../repositories/LocalGovernmentRepository";
import Multiselect from "@vueform/multiselect";
import { getEnglishNumber } from "./../../helper/helper";
import { englishToNepaliNumber } from "nepali-number";
import PdfDownload from "./../../components/shared/PdfDownload.vue";
const NoDataFound = defineAsyncComponent(() =>
  import("../../components/NoDataFound.vue")
);

export default {
  components: {
    Multiselect,
    NoDataFound,
    PdfDownload,
  },
  data() {
    return {
      englishToNepaliNumber,
      decisions: {},
      years: {},
      selected_year: "",
      default_year: "",
      default: {
        placeholder: "multiselect-placeholder",
      },
    };
  },
  watch: {
    selected_year: {
      handler: "getFilteredDecisions",
    },
  },
  async mounted() {
    let { data } = await LocalGovernmentRepo.getDecisions();
    this.decisions = data.data;
    this.years = this.getYearsOption(data.metadata.years);
    this.selected_year = data.metadata.years[this.years.length - 1];
    this.default_year = "आ. व. " + data.metadata.years[this.years.length - 1];
    this.$store.dispatch("setLoading", false);
  },
  methods: {
    getYearsOption(years) {
      let tempYear = [];

      for (const year in years) {
        tempYear.push({ value: years[year], label: "आ. व. " + years[year] });
      }

      return tempYear;
    },
    async getFilteredDecisions() {
      this.selected_year = this.selected_year
        ? this.selected_year
        : this.years[this.years.length - 1].value;
      let year = this.selected_year ? getEnglishNumber(this.selected_year) : "";
      let { data } = await LocalGovernmentRepo.getDecisions(
        `?fiscalYear=${year}`
      );
      this.decisions = data.data;
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.decision {
  &-wrapper {
    padding-bottom: 80px;
  }
  &__content {
    max-width: 875px;
    padding: 0 20px;
    position: relative;
    ul li:only-child {
      margin-bottom: 0;
    }
  }
  &__card {
    &--inner {
      background: $neutrals-white;
      border-radius: 20px;
      padding: 20px 40px;
    }
  }
}
</style>
